import css from './index.module.scss';
import { Button } from '@components/base';
import AmountDetailContainer from './amount-detail-container';
import { useFormContext } from 'react-hook-form';


interface AmountDetailContainerProps {
  onFormSubmit: (data: any) => void;
  onBackClick?: () => void;
  onCancelClick?: () => void;
}

const AmountDetailForm = (props: AmountDetailContainerProps) => {
  const { onFormSubmit, onBackClick, onCancelClick } = props;

  const { handleSubmit } = useFormContext();
 
  return (
    <form noValidate onSubmit={handleSubmit(onFormSubmit)} className={css.formWrapper}>
      <AmountDetailContainer />
      <div className={css.actionButtonWrapper}>
        <Button onClick={onCancelClick} variant="text">Cancel</Button>
        <div className={css.actionWrapper}>
          <Button variant="outlined-secondary" onClick={onBackClick}>
            Back
          </Button>
          <Button type='submit' >Save & Proceed</Button>
        </div>
      </div>
      <div>
      </div>
    </form>
  );
};

export default AmountDetailForm;
