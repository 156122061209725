import css from './index.module.scss';
import { Button } from '@components/base';
import { useFormContext } from 'react-hook-form';
import { IBankDetailForm } from '@helpers/types/supplier';
import CRLDetailsFields from './crl-details-fields';

interface CRLDetailsFormProps {
  editMode?: boolean;
  onFormSubmit: (data: any) => void;
  onBackClick?: () => void;
  onCancelClick?: () => void;
}

const CRLDetailsForm = (props: CRLDetailsFormProps) => {
  const { onBackClick, onCancelClick, onFormSubmit, editMode = false } = props;

  const {
    handleSubmit,
  } = useFormContext();

  return (
    <form className={css.formWrapper} onSubmit={handleSubmit(onFormSubmit)} noValidate>
      <CRLDetailsFields />
      <div className={css.actionButtonWrapper}>
        <Button variant="text" onClick={onCancelClick}>
          Cancel
        </Button>
        <div className={css.actionWrapper}>
          <Button variant="outlined-secondary" onClick={onBackClick}>
            Back
          </Button>
          <Button type="submit">
            Save
          </Button>
        </div>
      </div>
    </form>
  );
};

export default CRLDetailsForm;
