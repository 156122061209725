/* eslint-disable @typescript-eslint/no-var-requires */
import { useState, useEffect } from 'react';
import {
  getDocuments,
  getAllOrderDocZip,
  uploadOrderOtherDoc,
  updateOrderOtherDoc,
  getAddDocFilterOptions
} from '@services/order.service';

import DocumentTable from '../table';
import { Button, IconNode } from '@components/base';
import notify from '@helpers/toastify-helper';
import { useForm, FormProvider } from 'react-hook-form';
import { addOtherDocument } from '@helpers/yup/add-document.schema';
import { yupResolver } from '@hookform/resolvers/yup';
import css from './index.module.scss';
import Filters from '../filters';
import Images from '@assets/images';
import UploadOrderDocument from '@components/common/upload-order-document';
import Skeleton from '@components/base/skeleton';
import { getPermissionFromLocalStorage } from '@helpers/utils';
import { USER_TYPES } from '@helpers/constants';
import TableSkeleton from '@components/common/table-skeleton';
const FileSaver = require('file-saver');

interface DocumentTabProps {
  orderId: string;
  readableOrderId: string;
}

export interface IFilterState {
  tags: Array<any>;
  supplier: Array<any>;
  order_item: Array<any>;
}

interface TableGrid {
  check_box: boolean;
  document_type_display_value: string;
  order_item_name: string;
  created_at: string;
  created_by: string;
  document: TableGrid;
  display_name: string;
  tags: string[];
  customer_document_info: {
    created_at: string;
    document_object: string;
    document_url: string;
  };
  order_document_id: string;
}

interface DocumentTabState {
  docData: Array<TableGrid>;
  isLoading: boolean;
  supplierDocumentUploadModal: boolean;
  editMode: string | null;
  documentTypeFilterOptions: Array<any>;
  orderItemFilterOptions: Array<any>;
  supplierFilterOptions: Array<any>;
  tagsFilterOptions: Array<any>;
  defaultSupplierOptions: Array<any>;
  selectedFilters: IFilterState;
  selectedDocs: Array<string>;
  sortedDocData: Array<TableGrid>;
  currentStatus: number;
}

interface formType {
  type: any;
  internal_tags: Array<any>;
  name: string | null;
  document_type: string;
  remarks: string | null;
  document_object: File | null;
  supplier: any;
  order_item: any;
  order_document_id: string | null;
  product_required: boolean;
  supplier_required: boolean;
  edit_mode: boolean;
  reason_for_update: string | null;
}

const InternalView = (props: DocumentTabProps) => {
  const { orderId, readableOrderId } = props;

  const formMethods = useForm<formType>({
    resolver: yupResolver(addOtherDocument),
    defaultValues: {
      type: null,
      internal_tags: [],
      name: null,
      document_type: 'OTHERS', //This will be deprecated
      remarks: null,
      document_object: null,
      supplier: null,
      order_item: null,
      order_document_id: null,
      product_required: false,
      supplier_required: false,
      edit_mode: false,
      reason_for_update: null
    },
    shouldUnregister: true
  });

  const { setValue } = formMethods;

  const [documentState, setDocumentState] = useState<DocumentTabState>({
    docData: [],
    isLoading: false,
    supplierDocumentUploadModal: false,
    editMode: null,
    documentTypeFilterOptions: [],
    orderItemFilterOptions: [],
    supplierFilterOptions: [],
    tagsFilterOptions: [],
    defaultSupplierOptions: [],
    selectedFilters: {
      tags: [],
      supplier: [],
      order_item: []
    },
    selectedDocs: [],
    sortedDocData: [],
    currentStatus: 0,
  });

  const {
    docData,
    selectedDocs,
    isLoading,
    supplierDocumentUploadModal,
    editMode,
    documentTypeFilterOptions,
    orderItemFilterOptions,
    supplierFilterOptions,
    tagsFilterOptions,
    selectedFilters,
    sortedDocData,
    currentStatus
  } = documentState;

  useEffect(() => {
    getDocumentData();
    getFilterOptions();
  }, []);

  const getDocumentData = async (filters?: IFilterState) => {
    setDocumentState((prevState: DocumentTabState) => ({
      ...prevState,
      isLoading: true
    }));
    const documentData = await getDocuments(orderId, filters);
    if (documentData?.success) {
      if (documentData.data?.results) {
        documentData.data.results.forEach((doc: TableGrid) => {
          doc.check_box = false;
        });
        setDocumentState((prevState: any) => ({
          ...prevState,
          docData: documentData.data?.results,
          sortedDocData: documentData.data?.results,
          isLoading: false
        }));
      }
    } else {
      notify({
        title: 'We have little problem',
        message: documentData?.error ?? 'There was an error fetching documents.',
        severity: 'error'
      });
      setDocumentState((prevState: DocumentTabState) => ({
        ...prevState,
        isLoading: false
      }));
    }
  };

  const getZip = async () => {
    const copyOfDocData = sortedDocData;
    setDocumentState((prevState: DocumentTabState) => ({
      ...prevState,
      isLoading: true
    }));
    const documentsToBeDownloaded = selectedDocs?.length
      ? selectedDocs
      : sortedDocData?.map((doc: TableGrid) => doc.order_document_id);
    const documentData = await getAllOrderDocZip(orderId, documentsToBeDownloaded);
    setDocumentState((prevState: DocumentTabState) => ({
      ...prevState,
      isLoading: false
    }));
    setDocumentState((prevState: DocumentTabState) => ({
      ...prevState,
      selectedDocs: []
    }));
    if (typeof documentData == 'object' && !documentData?.error) {
      const blob = new Blob([documentData], { type: 'application/zip' });
      FileSaver.saveAs(blob, `${readableOrderId ?? `ORDER`}.zip`);
      const updatedDocData = copyOfDocData.map((item: TableGrid) => ({
        ...item,
        check_box: false
      }));
      setDocumentState((prevState: DocumentTabState) => ({
        ...prevState,
        selectedDocs: [],
        docData: updatedDocData,
        sortedDocData: updatedDocData
      }));
    } else {
      notify({
        title: 'We have little problem',
        message: documentData?.error ?? 'There was an error downloading documents.',
        severity: 'error'
      });
    }
  };

  const onClose = () =>
    setDocumentState((prevState: DocumentTabState) => ({
      ...prevState,
      supplierDocumentUploadModal: false
    }));

  const onFormSubmit = async (data: any) => {
    const formData = new FormData();
    if (data.document_object) {
      formData.append('document_object', data.document_object as Blob);
    } else if (!editMode) {
      notify({
        title: 'We have little problem',
        message: `Document is required`,
        severity: 'error'
      });
      return;
    }
    if (data?.type) {
      formData.append('type', data.type.value);
    }
    if (data?.name) {
      formData.append('name', data.name);
    }
    if (data?.supplier) {
      formData.append('supplier', data.supplier.supplier_id);
    }
    if (data?.remarks) {
      formData.append('remarks', data.remarks);
    }
    if (data?.order_item) {
      formData.append('order_item', data.order_item.order_item_id);
    }
    if (data?.internal_tags?.length) {
      data?.internal_tags.forEach((tag: any) => {
        formData.append('internal_tags', tag.value);
      });
    }
    formData.append('document_type', 'OTHERS');

    if (data?.reason_for_update) {
      formData.append('reason_for_update', data.reason_for_update);
    }

    if (!editMode) {
      formData.append('uploaded_outside_workflow', 'true');
    }

    setDocumentState((prevState: DocumentTabState) => ({
      ...prevState,
      isLoading: true
    }));
    let response;
    if (data.order_document_id) {
      response = await updateOrderOtherDoc(orderId, formData, data.order_document_id);
    } else {
      response = await uploadOrderOtherDoc(orderId, formData);
    }
    setDocumentState((prevState: DocumentTabState) => ({
      ...prevState,
      isLoading: false
    }));
    if (response?.success) {
      notify({
        title: 'Success',
        message: `Document ${data.order_document_id ? `updated` : `uploaded`} successfully`,
        severity: 'success'
      });
      onClose();
      getDocumentData();
    } else {
      notify({
        title: 'We have little problem',
        message:
          response.error ??
          `There was an error ${data.order_document_id ? `updating` : `uploading`} document.`,
        severity: 'error'
      });
    }
  };

  const openUpdateTagsModal = (data: any) => {
    setValue('internal_tags', data.internal_tags_fe_display);
    setValue('order_document_id', data.order_document_id);
    setValue('type', {
      value: data.type,
      label: data.document_type_display_value
    });
    setValue('name', data.name);
    setValue('remarks', data.remarks);
    if (data.supplier) {
      setValue('supplier', {
        supplier_id: data.supplier,
        supplier_name: data.supplier_name
      });
    }

    if (data.order_item) {
      setValue('order_item', {
        order_item_id: data.order_item,
        order_item_name: data.order_item_name
      });
    }

    setDocumentState((prevState: DocumentTabState) => ({
      ...prevState,
      supplierDocumentUploadModal: true,
      editMode: data?.is_system_generated ? 'SYSTEM_GENERATED' : 'USER_GENERATED'
    }));
    setValue('edit_mode', true);
  };

  const getFilterOptions = async () => {
    setDocumentState((prevState: DocumentTabState) => ({
      ...prevState,
      isLoading: true
    }));
    const response = await getAddDocFilterOptions(orderId);
    if (response?.success) {
      const { data } = response;
      setDocumentState((prevState: any) => ({
        ...prevState,
        documentTypeFilterOptions: data.document_type_filter_options,
        orderItemFilterOptions: data.order_item_filter_options,
        tagsFilterOptions: data.internal_tags_filter_options,
        supplierFilterOptions: data.supplier_filter_options,
        isLoading: false
      }));
    } else if (response.error) {
      notify({ message: response.error, severity: 'error' });
      setDocumentState((prevState: DocumentTabState) => ({
        ...prevState,
        isLoading: false
      }));
    }
  };

  const getFilteredDocuments = async (data: any, type: number) => {
    switch (type) {
      case 0:
        setDocumentState((prevState: any) => ({
          ...prevState,
          selectedFilters: {
            ...prevState.selectedFilters,
            tags: data?.length ? data: []
          }
        }));
        getDocumentData({
          ...selectedFilters,
          tags: data?.length ? data : []
        });
        break;
      case 1:
        setDocumentState((prevState: any) => ({
          ...prevState,
          selectedFilters: {
            ...prevState.selectedFilters,
            supplier: data?.length ? data: [] 
          }
        }));
        getDocumentData({
          ...selectedFilters,
          supplier: data?.length ? data : []
        });
        break;
      case 2:
        setDocumentState((prevState: any) => ({
          ...prevState,
          selectedFilters: {
            ...prevState.selectedFilters,
            order_item: data?.length ? data: []
          }
        }));
        getDocumentData({
          ...selectedFilters,
          order_item: data?.length ? data : []
        });
        break;
      default:
        return;
    }
  };

  const sortByDocumentTypeAZ = (a: TableGrid, b: TableGrid) => {
    return a.document_type_display_value.localeCompare(b.document_type_display_value);
  };

  const sortByDocumentTypeZA = (a: TableGrid, b: TableGrid) => {
    return b.document_type_display_value.localeCompare(a.document_type_display_value);
  };

  const sortByUploadedOnNewToOld = (a: TableGrid, b: TableGrid) => {
    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
  };

  const sortByUploadedOnOldToNew = (a: TableGrid, b: TableGrid) => {
    return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
  };

  const sortDocument = (status: number) => {
    let sortedData:Array<TableGrid>;
    switch (status) {
      case 0:
        sortedData = [...docData];
        break;
      case 1:
        sortedData = [...docData].sort(sortByDocumentTypeAZ);
        break;
      case 2:
        sortedData = [...docData].sort(sortByDocumentTypeZA);
        break;
      case 3:
        sortedData = [...docData].sort(sortByUploadedOnNewToOld);
        break;
      case 4:
        sortedData = [...docData].sort(sortByUploadedOnOldToNew);
        break;
      default:
        sortedData = [...docData];
    }

    setDocumentState((prevState: DocumentTabState) => ({
      ...prevState,
      sortedDocData: sortedData,
      currentStatus: status
    }));
  };

  const selectUnselectDoc = (e: React.ChangeEvent<HTMLInputElement>, doc: string) => {
    const copyOfOldSelectedDocs = selectedDocs;
    const copyOfDocData = sortedDocData;
    if (e.target.checked) {
      copyOfOldSelectedDocs.push(doc);
      copyOfDocData.forEach((item: TableGrid) => {
        if (copyOfOldSelectedDocs.includes(item.order_document_id)) {
          item.check_box = true;
        }
      });
    } else {
      const index = copyOfOldSelectedDocs.indexOf(doc);
      if (index > -1) {
        copyOfOldSelectedDocs.splice(index, 1);
        copyOfDocData.forEach((item: TableGrid) => {
          if (!copyOfOldSelectedDocs.includes(item.order_document_id)) {
            item.check_box = false;
          }
        });
      }
    }

    setDocumentState((prevState: DocumentTabState) => ({
      ...prevState,
      selectedDocs: copyOfOldSelectedDocs,
      docData: copyOfDocData,
      sortedDocData: copyOfDocData
    }));
  };

  if (isLoading) return <TableSkeleton colsNum={6} />;

  return (
    <>
      <div className={css.headerWrapper}>
        <div className={css.filterWrapper}>
          <Filters
            orderItemFilterOptions={orderItemFilterOptions}
            tagsFilterOptions={tagsFilterOptions}
            supplierFilterOptions={supplierFilterOptions}
            selectedFilters={selectedFilters}
            onChange={getFilteredDocuments}
          />
        </div>
        <div>
          <Button
            onClick={() => {
              setValue('edit_mode', false);
              setValue('order_document_id', null);
              setDocumentState((prevState: DocumentTabState) => ({
                ...prevState,
                supplierDocumentUploadModal: true,
                editMode: null
              }));
            }}>
            Add Document
          </Button>
        </div>
      </div>
      <div className={css.tableHeader}>
        {sortedDocData?.length ? (
          <Button
            variant="text"
            disabled={isLoading}
            onClick={getZip}
            startIcon={
              isLoading ? (
                <IconNode
                  className={css.iconRed}
                  src={Images.downloadGrey}
                  alt="download icon disable"
                />
              ) : (
                <IconNode className={css.iconRed} src={Images.downloadRed} alt="download icon" />
              )
            }>
            {isLoading
              ? `Downloading Zip`
              : `Download ${
                  selectedDocs?.length
                    ? `${selectedDocs?.length} ${
                        selectedDocs?.length === 1 ? 'document' : 'documents'
                      }`
                    : `All`
                }`}
          </Button>
        ) : (
          <></>
        )}
      </div>
      <DocumentTable
        data={sortedDocData}
        orderId={orderId}
        openUpdateTagsModal={openUpdateTagsModal}
        onDocSelection={selectUnselectDoc}
        getDocumentData={getDocumentData}
        sortDocument={sortDocument}
        currentStatus={currentStatus}
      />
      <FormProvider {...formMethods}>
        {supplierDocumentUploadModal && (
          <UploadOrderDocument
            open={supplierDocumentUploadModal}
            onClose={onClose}
            onFormSubmit={onFormSubmit}
            orderId={orderId}
            editMode={editMode}
          />
        )}
      </FormProvider>
    </>
  );
};

export default InternalView;

const DocumentSkeleton = () => {
  const userType = getPermissionFromLocalStorage().userType;
  const isInternal = userType === USER_TYPES.internal;
  return (
    <div>
      <div className={css.documentContainer}>
        {isInternal && (
          <div className={css.documentFilters}>
            <Skeleton animation="wave" width={'200px'} height={30} />
            <Skeleton animation="wave" width={'200px'} height={30} />
            <Skeleton animation="wave" width={'200px'} height={30} />
          </div>
        )}
        <Skeleton animation="wave" width={'150px'} height={30} />
      </div>
      <Skeleton variant="rectangular" width={'fitContent'} height={'650px'} />
    </div>
  );
};
