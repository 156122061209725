import css from './index.module.scss';
import { Button, IconNode, Typography } from '@components/base';
import { SelectLabel, TextField } from '@components/common';
import Images from '@assets/images';
import { IPaymentTermsFilter } from '@helpers/types/add-order';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

interface PaymentTermsProps {
  paymentTermFields: any;
  paymentTermRemove: (index: number) => void;
  handleAddPaymentTermField: () => void;
  paymentTermsOptions: IPaymentTermsFilter[];
  filteredPaymentTermsOptions: IPaymentTermsFilter[];
}

const PaymentTerms = (props: PaymentTermsProps) => {
  const {
    paymentTermFields,
    paymentTermRemove,
    handleAddPaymentTermField,
    paymentTermsOptions,
    filteredPaymentTermsOptions
  } = props;

  return (
    <div>
      <Typography variant="h5">Payment Terms</Typography>

      {paymentTermFields.map((item: any, index: number) => {
        const handleDeleteClick = () => paymentTermRemove(index);
        return (
          <PaymentTermDataForm
            key={item.id}
            index={index}
            payment_term={`payment_terms.${index}.payment_term`}
            percentage={`payment_terms.${index}.percentage`}
            days={`payment_terms.${index}.days`}
            showDelete={paymentTermFields.length > 1}
            onDeleteClick={handleDeleteClick}
            paymentTermsOptions={
              filteredPaymentTermsOptions?.length
                ? filteredPaymentTermsOptions
                : paymentTermsOptions
            }
          />
        );
      })}
      <Button
        variant="text"
        onClick={handleAddPaymentTermField}
        startIcon={<IconNode src={Images.plusRed} alt="add icon" />}>
        Add More Payment Term
      </Button>
    </div>
  );
};

interface PaymentTermDataFormProps {
  payment_term: string;
  percentage: string;
  days: string;
  showDelete: boolean;
  onDeleteClick: () => void;
  index: number;
  paymentTermsOptions: IPaymentTermsFilter[];
}

const PaymentTermDataForm = (props: PaymentTermDataFormProps) => {
  const { payment_term, percentage, days, showDelete, onDeleteClick, paymentTermsOptions } = props;
  const { control } = useFormContext();
  const paymentTermWatch = useWatch({
    name: payment_term,
    control
  });
  return (
    <>
      <div className={css.rowWrapper}>
        <Controller
          name={percentage}
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              required={paymentTermWatch?.percentage ?? false}
              inputMode="numeric"
              type="text"
              enterKeyHint="next"
              disabled={!paymentTermWatch?.percentage ?? true}
              label="Percentage Amount"
              placeholder="Enter percentage"
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              rootClassName={css.fieldWrapper}
            />
          )}
        />
        <Controller
          name={payment_term}
          control={control}
          render={({ field, fieldState }) => (
            <SelectLabel
              {...field}
              required
              isSearchable
              options={paymentTermsOptions}
              label="Payment Term"
              placeholder="Select option"
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              getOptionLabel={(option: any) => option.payment_term}
              getOptionValue={(option: any) => option.payment_term}
              rootClassName={css.fieldWrapper}
            />
          )}
        />
        <Controller
          name={days}
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              required={paymentTermWatch?.days ?? false}
              inputMode="numeric"
              enterKeyHint="next"
              label="Days"
              type="text"
              placeholder="Enter days"
              disabled={!paymentTermWatch?.days ?? true}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              rootClassName={css.fieldWrapper}
            />
          )}
        />
        {showDelete && (
          <Button
            variant="text"
            title="Delete Item"
            onClick={onDeleteClick}
            startIcon={<IconNode src={Images.deleteRed} alt="delete icon" />}></Button>
        )}
      </div>
    </>
  );
};

export default PaymentTerms;
