import css from './index.module.scss';
import { Button } from '@components/base';
import { useFormContext } from 'react-hook-form';
import AdvancePaymentContainer from './advance-payment-container';


const AdvancePaymentForm = (props: any) => {
  const { onFormSubmit, onBackClick, onCancelClick } = props;

  const {
    handleSubmit,
  } = useFormContext();

  return (
    <form noValidate onSubmit={handleSubmit(onFormSubmit)} className={css.formWrapper}>
      <AdvancePaymentContainer />
      <div className={css.actionButtonWrapper}>
        <Button onClick={onCancelClick} variant="text">Cancel</Button>
        <div className={css.actionWrapper}>
          <Button variant="outlined-secondary" onClick={onBackClick}>
            Back
          </Button>
          <Button type='submit'>Save & Proceed</Button>
        </div>
      </div>
    </form>
  );
};

export default AdvancePaymentForm;
