import { memo, useContext } from 'react';
import css from './index.module.scss';
import { BoxContainer, TextField } from '@components/common';
import { Controller, useFormContext } from 'react-hook-form';
import { IconNode } from '@components/base';
import Images from '@assets/images';
import OrderContext from '@pages/finance/generate-crl/OrderContext';

const AmountDetailContainer = () => {
  const { control } = useFormContext();
  const OrderData = useContext(OrderContext);
  const { total } = OrderData.orderInfo;


  return (
    <BoxContainer title="Amount Details">
      <div className={css.boxWrapper}>
        <div className={css.rowWrapper}>
          <div className={css.fieldsWrapper}>
            <Controller
              name={`total_amount`}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  required
                  type="text"
                  inputMode="decimal"
                  enterKeyHint="next"
                  autoComplete="off"
                  value={total}
                  label="Total Amount"
                  placeholder="Enter Total Amount"
                  error={fieldState.invalid}
                  endIcon={
                    fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                  }
                  helperText={fieldState.error?.message}
                />
              )}
            />
            <Controller
              name={`due_amount`}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  required
                  style={{ marginLeft: '12px' }}
                  inputMode="decimal"
                  enterKeyHint="next"
                  autoComplete="off"
                  label="Due Amount"
                  placeholder="Enter due amount"
                  error={fieldState.invalid}
                  endIcon={
                    fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                  }
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </div>
        </div>
      </div>
    </BoxContainer>
  );
};

export default memo(AmountDetailContainer);
