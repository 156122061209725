import { memo } from 'react';
import css from './index.module.scss';
import { BoxContainer, TextField } from '@components/common';
import { Controller, useFormContext } from 'react-hook-form';
import { IconNode } from '@components/base';
import Images from '@assets/images';

const AdvancePaymentContainer = () => {
  const { control } = useFormContext();

  return (
    <BoxContainer title="Advance Payment">
      <div className={css.boxWrapper}>
      <Controller
        name={`payment_reference_no`}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            required
            type="text"
            inputMode="text"
            enterKeyHint="done"
            label="Payment Reference Number"
            placeholder="Enter Payment Reference Number"
            error={fieldState.invalid}
            endIcon={
              fieldState.invalid && (
                <IconNode src={Images.alertError} alt="Error Icon" />
              )
            }
            helperText={fieldState.error?.message}
          />
        )}
      />
      </div>
    </BoxContainer>
  );
};

export default memo(AdvancePaymentContainer);
