import * as yup from 'yup';
import { MAX_FILE_SIZE, SUPPORTED_FORMATS } from '@helpers/constants';

const selectSchema = yup.object().shape({
  value: yup.string().required(),
  label: yup.string().required()
});

const categorySchema = yup.object().shape({
  category_name: yup.string().required(),
  category_value: yup.number().required()
});

const orderItemSchema = yup.object().shape({
  order_item_id: yup.string().required(),
  order_item_name: yup.string().required(),
  supplier_id: yup.string().optional().nullable()
});

const supplierSchema = yup.object().shape({
  supplier_id: yup.string().required(),
  supplier_name: yup.string().required()
});

/**
 * Document name will not be added in phase 1 hence commented, DO NOT DELETE beacuse it might be added again
 */
const addDocument = yup.object().shape({
  document_id: yup.string().optional(),
  document_description: yup.string().optional(),
  document_type: selectSchema.required('Document Type is required'),
  // document_name: yup.string().trim().required('Document Name is required'),
  document_object: yup
    .mixed()
    .required('Document Object is required')
    .test('fileSize', 'File Size is too large', (value: any) => value.size <= MAX_FILE_SIZE)
    .test('fileType', 'Unsupported File Format', (value: any) =>
      SUPPORTED_FORMATS.includes(value.type)
    ),
  reason_for_update: yup.string().when(['document_object', 'edit_mode'], (val, schema) => {
    if (val?.length && val[0] && val[1]) {
      return yup.string().required('Reason for Upload is required');
    } else {
      return yup.string().optional().nullable();
    }
  })
});

const addOtherDocument = yup.object().shape({
  type: yup.mixed().required('Document Type is required'),
  internal_tags: yup.array().of(selectSchema).min(1, 'Tags are required'),
  name: yup.string().trim().optional().nullable(),
  document_type: yup.string().trim().optional().nullable(), //This will be deprecated
  remarks: yup.string().trim().optional().nullable(),
  supplier: yup.mixed().when('supplier_required', (val, schema) => {
    if (val?.length && val[0]) {
      return yup.mixed().required('Supplier is required');
    } else {
      return yup.mixed().optional().nullable();
    }
  }),
  order_item: yup.mixed().when('product_required', (val, schema) => {
    if (val?.length && val[0]) {
      return yup.mixed().required('Product is required');
    } else {
      return yup.mixed().optional().nullable();
    }
  }),
  order_document_id: yup.string().trim().optional().nullable(),
  product_required: yup.boolean().optional().nullable(),
  supplier_required: yup.boolean().optional().nullable(),
  edit_mode: yup.mixed().optional().nullable(),
  document_object: yup.mixed().when('edit_mode', (val, schema) => {
    if (val?.length && val[0]) {
      return yup.mixed().optional().nullable();
    } else {
      return yup
        .mixed()
        .required('Document Object is required')
        .test('fileSize', 'File Size is too large', (value: any) => value.size <= MAX_FILE_SIZE)
        .test('fileType', 'Unsupported File Format', (value: any) =>
          SUPPORTED_FORMATS.includes(value.type)
        );
    }
  }),
  reason_for_update: yup.string().when(['document_object', 'edit_mode'], (val, schema) => {
    if (val?.length && val[0] && val[1]) {
      return yup.string().required('Reason for Upload is required');
    } else {
      return yup.string().optional().nullable();
    }
  })
});

const resolveInconsistencySchema = yup.object().shape({
  valid_document_type: yup.number().required('Document Type is required'),
  valid_document_object: yup.mixed().when('valid_document_type', (val, schema) => {
    if (val?.length && val[0] === 3) {
      return yup
        .mixed()
        .required('Document Object is required')
        .test('fileSize', 'File Size is too large', (value: any) => value.size <= MAX_FILE_SIZE)
        .test('fileType', 'Unsupported File Format', (value: any) =>
          SUPPORTED_FORMATS.includes(value.type)
        );
    } else {
      return yup.mixed().optional().nullable();
    }
  }),
  reason_for_update: yup.string().when('valid_document_type', (val, schema) => {
    if (val?.length && val[0] === 3) {
      return yup.string().required('Reason for Upload is required');
    } else {
      return yup.string().optional().nullable();
    }
  })
});

export { addDocument, addOtherDocument, resolveInconsistencySchema };
