import { Button, IconNode, Modal, Typography } from '@components/base';
import css from './index.module.scss';
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { SelectLabel, TextAreaLabel, TextField } from '@components/common';
import { TEAM_TAGS } from '@helpers/constants';
import { createNotes, editNotes } from '@services/order.service';
import notify from '@helpers/toastify-helper';
import { useEffect, useMemo } from 'react';
import { INotes } from '..';
import { addNotesSchema } from '@helpers/yup/add-notes-order.schema';
import { yupResolver } from '@hookform/resolvers/yup';
import Images from '@assets/images';

interface INotesCreateEditModalProps {
  open: boolean;
  onClose: () => void;
  notesData: INotes[];
  orderId: string;
  editNotesId: string;
  isEdit: boolean;
  handleCancel: () => void;
}

interface FormType {
  title: string;
  content: string;
  tags: { label: string; value: string } | null;
  isPrivate: { label: string; value: boolean };
}

const Visibility = [
  {
    label: 'Public',
    value: false
  },
  {
    label: 'Private',
    value: true
  }
];

const NotesCreateEditModal = (props: INotesCreateEditModalProps) => {
  const { open, onClose, notesData, orderId, editNotesId, isEdit, handleCancel } = props;
  const NotesForm = useForm<FormType>({
    resolver: yupResolver(addNotesSchema),
    defaultValues: {
      title: '',
      content: '',
      tags: null,
      isPrivate: { label: 'Private', value: true }
    }
  });

  const editNotesData = useMemo(() => {
    return notesData.filter((note: INotes) => note.id === editNotesId);
  }, [editNotesId]);
  useEffect(() => {
    if (isEdit) {
      const { title, message, is_private } = editNotesData[0];
      NotesForm.setValue('title', title);
      NotesForm.setValue('content', message);
      NotesForm.setValue(
        'isPrivate',
        is_private ? { label: 'Private', value: true } : { label: 'Public', value: false }
      );
    }
  }, []);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting, isDirty }
  } = NotesForm;

  const watch = useWatch({
    name: 'isPrivate',
    control
  });

  const handleFormSubmit: SubmitHandler<FormType> = async (data) => {
    const reqBody = {
      title: data.title,
      message: data.content,
      // team_tag: data.tags && data.tags?.value,
      is_private: data.isPrivate.value
    };
    const response = isEdit
      ? await editNotes(editNotesData[0]?.is_private, orderId, editNotesId, reqBody)
      : await createNotes(orderId, reqBody);
    if (response.success) {
      notify({
        message: isEdit ? 'Note updatted successfully' : 'Note created successfully',
        severity: 'success'
      });
    } else if (response.error) {
      notify({
        message: response.error,
        severity: 'error'
      });
    }
    NotesForm.reset();
    onClose();
  };

  return (
    <Modal open={open} onClose={handleCancel}>
      <div className={css.modalWrapper}>
        <div className={css.modalHeader}>
          <Typography variant="h3">{isEdit ? 'Edit Note' : 'Add Note'}</Typography>
          <IconNode
            src={Images.crossBlack}
            alt="close icon"
            component="button"
            className={css.closeButton}
            onClick={handleCancel}
          />
        </div>
        <form noValidate className={css.formWrapper} onSubmit={handleSubmit(handleFormSubmit)}>
          <section className={css.modalContent}>
            <Controller
              name="title"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  required
                  label="Title"
                  placeholder="Enter title"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
            <Controller
              name="content"
              control={control}
              render={({ field, fieldState }) => (
                <TextAreaLabel
                  {...field}
                  required
                  label="Description"
                  placeholder="Enter Description"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
            <div className={css.modalSelectLabel}>
              {/* {watch.value === false && (
                <Controller
                  name="tags"
                  control={control}
                  render={({ field, fieldState }) => (
                    <SelectLabel
                      {...field}
                      isSearchable
                      label="Tags"
                      options={TEAM_TAGS}
                      placeholder="Select one"
                      getOptionLabel={(option: any) => option.label}
                      getOptionValue={(option: any) => option.value}
                      rootClassName={css.blTypeFieldWrapper}
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              )} */}
              <Controller
                name="isPrivate"
                control={control}
                render={({ field, fieldState }) => (
                  <SelectLabel
                    {...field}
                    required
                    isSearchable
                    label="Visibility"
                    options={Visibility}
                    placeholder="Select one"
                    getOptionLabel={(option: any) => option.label}
                    getOptionValue={(option: any) => option.value}
                    rootClassName={css.blTypeFieldWrapper}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </div>
          </section>
          <div className={css.modalFooterButtons}>
            <Button variant="outlined-secondary" onClick={handleCancel} disabled={isSubmitting}>
              Cancel
            </Button>
            <Button variant="contained" type="submit" disabled={!isDirty}>
              Save
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default NotesCreateEditModal;
