import { Typography, Modal, IconNode, Button } from '@components/base';
import { InputDatePicker } from '@components/common';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import Images from '@assets/images';

import moment from 'moment';

import css from './index.module.scss';
import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { taskEstimateSchema } from '@helpers/yup/order-tasks.schema';

interface EstimateDatesModal {
  open: boolean;
  onClose: () => void;
  taskData: any;
  onComplete?: () => void;
  onFormSubmit: (data: any) => void;
}

const EstimateDatesModal = (props: EstimateDatesModal) => {
  const { open, onClose, taskData, onFormSubmit } = props;
  const { task_estimates } = taskData;
  const [tat, setTat] = useState(0);
  const { control, handleSubmit } = useForm<any>({
    resolver: yupResolver(taskEstimateSchema),
    defaultValues: {
      estimate_data: []
    },
    shouldUnregister: true
  });

  const { fields, append } = useFieldArray({
    control,
    name: 'estimate_data'
  });

  const watchField = useWatch({
    control,
    name: 'estimate_data'
  });

  useEffect(() => {
    if (task_estimates) {
      task_estimates.forEach((item: any, index: number) => {
        item.estimated_date = item?.actual_date
          ? new Date(item?.actual_date)
          : item?.estimated_date
          ? new Date(item.estimated_date as string)
          : null;
      });
      append(task_estimates);
    }
  }, [task_estimates]);

  useEffect(() => {
    if (watchField?.length) {
      const startDate = moment(watchField[0].estimated_date);
      const endDate = moment(watchField[watchField?.length - 1].estimated_date);
      const duration = endDate.diff(startDate, 'days');
      setTat(Number.isNaN(duration) || duration < 0 ? 0 : duration);
    }
  }, [watchField]);

  return (
    <Modal open={open} onClose={onClose}>
      <div className={css.modalWrapper}>
        <section className={css.modalHeader}>
          <Typography variant="h4">Add Estimates</Typography>
          <IconNode
            src={Images.crossBlack}
            alt="close icon"
            component="button"
            className={css.closeButton}
            onClick={onClose}
          />
        </section>

        <form noValidate className={css.formWrapper} onSubmit={handleSubmit(onFormSubmit)}>
          <section className={css.modalContent}>
            {fields.map((item: any, index: number) => {
              return (
                <div key={index} className={css.fieldWrapper}>
                  <Controller
                    name={`estimate_data.${index}.estimated_date`}
                    control={control}
                    render={({ field, fieldState }) => {
                      return (
                        <InputDatePicker
                          {...field}
                          required
                          value={field.value}
                          placeholder="Select date"
                          disabled={item.actual_date ? true : false}
                          label={item.state_type}
                          onSelect={(day: Date | undefined) => {
                            field.onChange(day ?? null);
                          }}
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                          rootClassName={css.fieldSpacing}
                        />
                      );
                    }}
                  />
                </div>
              );
            })}
          </section>
          <section className={css.extraInfoWrapper}>
            <div>
              <Typography variant="label">Total TAT</Typography>
              <Typography>
                {tat} {tat > 1 ? `Days` : `Day`}
              </Typography>
            </div>
          </section>
          <section className={css.modalFooter}>
            <Button variant="outlined-secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit">Save</Button>
          </section>
        </form>
      </div>
    </Modal>
  );
};

interface ContainerDataFormProps {
  container_type: string;
  index: number;
  container_number: string;
}

export default EstimateDatesModal;
