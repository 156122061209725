import { Button } from '@components/base';
import { useFormContext } from 'react-hook-form';
import css from './index.module.scss';
import PaymentTermContainer from './payment-term-container';

interface AmountDetailContainerProps {
  onFormSubmit: (data: any) => void;
  onBackClick?: () => void;
  onCancelClick?: () => void;
}


const PaymentTermForm = (props: AmountDetailContainerProps) => {
  const { onFormSubmit, onBackClick, onCancelClick } = props;

  const {
    handleSubmit,
  } = useFormContext();

  return (
    <form noValidate className={css.formWrapper} onSubmit={handleSubmit(onFormSubmit)} >
      <PaymentTermContainer />
      <div className={css.actionButtonWrapper}>
        <Button onClick={onCancelClick} variant="text">Cancel</Button>
        <div className={css.actionWrapper}>
          <Button variant="outlined-secondary" onClick={onBackClick}>
            Back
          </Button>
          <Button type='submit'>Save & Proceed</Button>
        </div>
      </div>
    </form>
  );
};

export default PaymentTermForm;
