import { Chip, IconNode, Typography } from '@components/base';
import css from './index.module.scss';
import Images from '@assets/images';
import Divider from '@components/base/divider';
import { TEAM_TAGS } from '@helpers/constants';
import { getFormattedDate, getFormattedTime } from '@helpers/utils';
import { getToken, parseJWT } from '@helpers/auth-helper';
import { INotes } from '..';
import AccessWrapper from '@authorization/access-wrapper';
import { DeletePrompt } from '@components/common';
import { useState } from 'react';

interface INotesCardProps {
  data: INotes;
  handleDelete: (noteId: string) => Promise<void>;
  handleEdit: (noteId: string) => void;
  actions: IActions;
}

const NotesCard = (props: INotesCardProps) => {
  const { data, handleDelete, handleEdit, actions } = props;
  const accessToken = getToken('access');
  const jwtData = parseJWT(accessToken);
  const [openDeletePrompt, setOpenDeletePrompt] = useState(false);
  // const team = TEAM_TAGS.find((tag) => tag.value === data?.team_tag);

  const handleDeleteNote = () => {
    setOpenDeletePrompt(false);
    handleDelete(data?.id);
  };

  const handleCancel = () => {
    setOpenDeletePrompt(false);
  };

  return (
    <div className={css.cardWrapper}>
      <div className={css.cardHeader}>
        <Typography variant="h5">{data?.title}</Typography>
        {jwtData?.user_id === data?.created_by && (
          <div className={css.cardHeaderEditDelete}>
            <AccessWrapper show={actions?.update}>
              <IconNode
                src={Images.editDark}
                alt="Edit Note"
                component="button"
                className={css.closeButton}
                onClick={() => handleEdit(data?.id)}
              />
            </AccessWrapper>
            <AccessWrapper show={actions?.delete}>
              <IconNode
                src={Images.deleteRed}
                alt="Delete Note"
                component="button"
                className={css.closeButton}
                onClick={() => setOpenDeletePrompt(true)}
              />
            </AccessWrapper>
          </div>
        )}
      </div>
      <Typography variant="label">{data?.message}</Typography>
      <Divider className={css.notesDivider} />
      <div className={css.cardCreaterInfo}>
        <div className={css.labelValue}>
          <Typography variant="label">Added By</Typography>
          {jwtData?.user_id === data?.created_by ? (
            <Chip label="Me" variant="outlined" />
          ) : (
            <Chip label={data?.created_by_full_name} variant="filled-blue" />
          )}
        </div>
        <div className={css.labelValue}>
          <Typography variant="label">Added On</Typography>
          <Typography variant="p">{getFormattedDate(data?.created_at)} - {getFormattedTime(data?.created_at)}</Typography>
        </div>
      </div>
      {/* {team && (
        <>
          <Divider className={css.notesDivider} />
          <div className={css.cardTags}>
            <Chip label={team?.label} variant="filled-blue" />
          </div>
        </>
      )} */}
      {openDeletePrompt && (
        <DeletePrompt
          open={openDeletePrompt}
          onClose={() => handleCancel()}
          onConfirm={() => handleDeleteNote()}
          message="Are you sure you want to delete the note?"
        />
      )}
    </div>
  );
};

export default NotesCard;
