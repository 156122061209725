import { useEffect, useState } from 'react';
import css from './index.module.scss';
import { Loader, Typography } from '@components/base';
import { BankDetailForm, CustomerDetailForm, SideBar } from '@components/common';
import { useForm, FormProvider, useFormContext, SubmitHandler } from 'react-hook-form';
import AmountDetailForm from './components/amount-detail-form';
import PaymentTermForm from './components/payment-term-form';
import AdvancePaymentForm from './components/advance-payment-form';
import { IBankDetailForm } from '@helpers/types';
import { addBankDetailSchema, boeCustomerSchema } from '@helpers/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import PocForm from './components/poc-form';
import CRLDetailsForm from '@components/common/crl-details-form';
import {
  crlSchema,
  advancePaymentSchema,
  amountDetailsSchema,
  paymentTermsSchema
} from '@helpers/yup/add-crl-details.schema';
import { addCRLDetails, fetchOrderInfoFinance, getCRLDetails } from '@services/order.service';
import { format } from 'date-fns';
import notify from '@helpers/toastify-helper';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { OrderInfo } from '@helpers/types/order';
import { CLIENT_ROUTES } from '@router/routes';
import { cities, countries, states } from '@helpers/constants';
import OrderContext from './OrderContext';

interface GenerateCRLStates {
  activeStep: number;
  isLoading: false;
  orderInfo: OrderInfo | null;
  crlInfo: any;
}

const GenerateCRL = (props: any) => {
  const [crlStates, setCrlStates] = useState<GenerateCRLStates>({
    activeStep: 0,
    isLoading: false,
    orderInfo: null,
    crlInfo: null
  });
  const { activeStep, isLoading } = crlStates;
  const urlParams = useParams();
  const { id, taskid } = urlParams;
  const navigate = useNavigate();
  const isEdit = location.pathname.split('/')[5] === 'edit';
  const locationData = useLocation();
  const { data } = locationData.state || {};

  const customerDetailForm = useForm({
    resolver: yupResolver(boeCustomerSchema),
    defaultValues: {
      name: '',
      address_line1: '',
      address_line2: '',
      zip_code: '',
      city: null || '',
      state: null || '',
      country: null || ''
    }
  });
  const bankDetailForm = useForm<IBankDetailForm>({
    defaultValues: {
      bankdetails: [
        {
          bank_id: '',
          bank_name: '',
          branch_name: '',
          bank_account_holder_name: '',
          account_number: '',
          ifsc_code: '',
          swift_code: '',
          address_line1: '',
          address_line2: '',
          zip_code: '',
          city: null,
          state: null,
          country: null
        }
      ]
    },
    resolver: yupResolver(addBankDetailSchema)
  });

  const paymentTerm = useForm({
    resolver: yupResolver(paymentTermsSchema),
    defaultValues: {
      lc_number: '',
      lc_date: '' || null || new Date(),
      shipping_bill_number: '',
      customer_lc_issuing_bank: ''
    }
  });

  const advancePaymentForm = useForm({
    // resolver: yupResolver(advancePaymentSchema),
    defaultValues: {
      payment_reference_no: ''
    }
  });

  const amountDetails = useForm({
    defaultValues: {
      total_amount: String(crlStates?.orderInfo?.total),
      due_amount: ''
    }
  });

  const CRLDetailForm = useForm({
    resolver: yupResolver(crlSchema),
    defaultValues: {
      document_date: '' || null || new Date(),
      crl_remitted_amount: '',
      crl_remitted_amount_in_words: '',
      value_date: '' || null || new Date(),
      customers_correspondent_bank_details: '',
      purpose_of_remittance: '',
      foreign_bank_charges: '',
      advance_import_remittance: false,
      merchanting_trade_transaction: false,
      part_payment: false,
      debit_from_cc_acc_no: '',
      amount_to_debit_from_cc: '',
      port_of_loading: '',
      forward_contract_no_and_date: '',
      forward_contract_amount: '',
      amount_to_be_utilized: '',
      due_date_of_contract: '' || null || new Date()
    }
  });

  const { reset: customerReset } = customerDetailForm;
  const { reset: elchemyBankDetails } = bankDetailForm;
  const { reset: paymentTermReset } = paymentTerm;
  const { reset: advancePaymentReset } = advancePaymentForm;
  const { reset: amountDetailsReset } = amountDetails;
  const { reset: crlDetailFormReset } = CRLDetailForm;

  const getOrderInfo = async () => {
    const { id } = urlParams;
    if (id) {
      setCrlStates((prevState: any) => ({ ...prevState, isLoading: true }));
      const response = await fetchOrderInfoFinance(String(id));
      if (response.success) {
        const { data } = response;
        const orderInfo = data as OrderInfo;

        setCrlStates((prevState) => ({
          ...prevState,
          orderInfo: data,
          isLoading: false
        }));
        customerReset({
          name: `${orderInfo.customer?.name}`,
          address_line1: `${orderInfo.customer_address?.address_line1}`,
          address_line2: `${orderInfo.customer_address?.address_line2}`,
          zip_code: `${orderInfo.customer_address?.zip_code}`,
          city: `${cities.find((c) => c.label === orderInfo.customer_address?.city)}`,
          state: `${states.find((c) => c.label === orderInfo.customer_address?.state)}`,
          country: `${countries.find((c) => c.value === orderInfo.customer_address?.country)}`
        });
        elchemyBankDetails({
          bankdetails: [
            {
              bank_id: '',
              bank_name: `${orderInfo.elchemy_bank?.bank_name}`,
              branch_name: `${orderInfo.elchemy_bank?.branch_name}`,
              bank_account_holder_name: `${orderInfo.elchemy_bank?.bank_account_holder_name}`,
              account_number: `${orderInfo.elchemy_bank?.account_number}`,
              ifsc_code: `${orderInfo.elchemy_bank?.ifsc_code}`,
              swift_code: `${orderInfo.elchemy_bank?.swift_code}`,
              address_line1: `${orderInfo.elchemy_bank?.address_line1}`,
              address_line2: `${orderInfo.elchemy_bank?.address_line2}`,
              zip_code: `${orderInfo.elchemy_bank?.zip_code}`,
              city: cities.find((c) => c.label === orderInfo.elchemy_bank?.city),
              state: states.find((c) => c.label === orderInfo.elchemy_bank?.state),
              country: countries.find((c) => c.value === orderInfo.elchemy_bank?.country)
            }
          ]
        });
      } else if (response.error) navigate(`/${CLIENT_ROUTES.order}`);
    } else navigate(`/${CLIENT_ROUTES.order}`);
  };

  const getCRL = async () => {
    setCrlStates((prevState: any) => ({ ...prevState, isLoading: true }));
    const response = await getCRLDetails(String(id));
    if (response.success) {
      const { data } = response;

      setCrlStates((prevState) => ({
        ...prevState,
        crlInfo: data,
        isLoading: false
      }));

      const LC_Date = new Date(`${data?.lc_date ?? ''}`) ?? '';
      const CRL_Date = new Date(`${data?.document_date ?? ''}`) ?? '';
      const VALUE_Date = new Date(`${data?.value_date ?? ''}`) ?? '';
      const DUE_CONTRACT_Date = new Date(`${data?.due_date_of_contract ?? ''}`) ?? '';

      paymentTermReset({
        lc_number: `${data?.lc_number}`,
        lc_date: LC_Date ?? null ?? '',
        shipping_bill_number: `${data?.shipping_bill_number}`,
        customer_lc_issuing_bank: ''
      });
      advancePaymentReset({
        payment_reference_no: `${data?.payment_reference_no}`
      });
      amountDetailsReset({
        total_amount: String(crlStates?.orderInfo?.total),
        due_amount: ''
      });
      crlDetailFormReset({
        document_date: CRL_Date ?? '' ?? null,
        crl_remitted_amount: `${data?.crl_remitted_amount}`,
        crl_remitted_amount_in_words: `${data?.crl_remitted_amount_in_words}`,
        value_date: VALUE_Date ?? '' ?? null,
        customers_correspondent_bank_details: `${data?.customers_correspondent_bank_details}`,
        purpose_of_remittance: `${data?.purpose_of_remittance}`,
        foreign_bank_charges: `${data?.foreign_bank_charges}`,
        advance_import_remittance: data?.advance_import_remittance,
        merchanting_trade_transaction: data?.merchanting_trade_transaction,
        part_payment: data?.part_payment,
        debit_from_cc_acc_no: `${data?.debit_from_cc_acc_no}`,
        amount_to_debit_from_cc: `${data?.amount_to_debit_from_cc}`,
        port_of_loading: `${data?.port_of_loading}`,
        forward_contract_no_and_date: `${data?.forward_contract_no_and_date ?? ''}`,
        forward_contract_amount: `${data?.forward_contract_amount}`,
        amount_to_be_utilized: `${data?.amount_to_be_utilized}`,
        due_date_of_contract: DUE_CONTRACT_Date ?? '' ?? null
      });
    } else {
      notify({
        message: response.error ?? 'Error fetching CRL!',
        severity: 'error',
        dismissible: true
      });
    }
  };

  useEffect(() => {
    getOrderInfo();
    if (isEdit) {
      getCRL();
    }
  }, [urlParams]);

  const handleDataSubmit: SubmitHandler<any> = async () => {
    const prn = advancePaymentForm.control._formValues?.payment_reference_no;
    const customerIB = paymentTerm.control._formValues?.customer_lc_issuing_bank.label;
    const LCDate = paymentTerm.control._formValues?.lc_date;
    const SPB = paymentTerm.control._formValues?.shipping_bill_number;
    const formattedDate = format(new Date(LCDate), 'yyyy-MM-dd');
    const crl_fromatted_date = format(
      new Date(CRLDetailForm?.control?._formValues?.document_date),
      'yyyy-MM-dd'
    );

    const newData = {
      ...CRLDetailForm.control._formValues,
      ...paymentTerm.control._formValues,
      ...advancePaymentForm.control._formValues,
      order: id,
      document_date: crl_fromatted_date ?? format(new Date(), 'yyyy-MM-dd'),
      due_date_of_contract: format(
        new Date(CRLDetailForm.control._formValues.due_date_of_contract),
        'yyyy-MM-dd'
      ),
      value_date: format(CRLDetailForm.control._formValues.value_date, 'yyyy-MM-dd'),
      payment_reference_no: prn,
      customer_lc_issuing_bank: customerIB,
      shipping_bill_number: SPB,
      lc_date: formattedDate
    };

    const updateData = {
      ...CRLDetailForm.control._formValues,
      ...paymentTerm.control._formValues,
      ...advancePaymentForm.control._formValues,
      order: id,
      document_date: crl_fromatted_date ?? format(new Date(), 'yyyy-MM-dd'),
      due_date_of_contract: format(
        new Date(CRLDetailForm.control._formValues.due_date_of_contract),
        'yyyy-MM-dd'
      ),
      value_date: format(CRLDetailForm.control._formValues.value_date, 'yyyy-MM-dd'),
      payment_reference_no: prn,
      customer_lc_issuing_bank: customerIB,
      shipping_bill_number: SPB,
      lc_date: formattedDate,
      document_number: crlStates?.crlInfo?.document_number
    };

    const response = await addCRLDetails(taskid, isEdit ? updateData : newData);
    if (response.success) {
      notify({ message: 'CRL Generated Successfully!', severity: 'success', dismissible: true });
      navigate(`/order/${data.orderId}`, {
        replace: true
      });
    } else {
      notify({
        message: response.error ?? 'Error generating CRL!',
        severity: 'error',
        dismissible: true
      });
    }
    return response;
  };

  const handleSideNavigation = (value: number) => {
    setCrlStates((prevState) => ({ ...prevState, activeStep: value }));
  };

  const handleApproveClick = () => {
    setCrlStates((prevState) => ({ ...prevState, activeStep: activeStep + 1 }));
  };

  const onBack = () => {
    setCrlStates((prevState) => ({ ...prevState, activeStep: activeStep - 1 }));
  };

  const onCancel = () => {
    navigate(`/order/${id}`);
  };

  return (
    <OrderContext.Provider value={crlStates}>
      <main className={css.mainWrapper}>
        <div className={css.headerWrapper}>
          <Typography variant="h4">Generate CRL</Typography>
          <Typography variant="span">0{activeStep + 1} of 07</Typography>
        </div>
        <section className={css.subMainWrapper}>
          <div className={css.sidebarWrapper}>
            <SideBar activeStep={activeStep} onClick={handleSideNavigation}>
              <SideBar.Item label="Payment Details" value={0} />
              <SideBar.Item label="Amount Details" value={1} disabled={activeStep! <= 1} />
              <SideBar.Item label="Advance Payment" value={2} disabled={activeStep! <= 2} />
              <SideBar.Item label="Payment Term" value={3} disabled={activeStep! <= 3} />
              <SideBar.Item label="Customer Details" value={4} disabled={activeStep! <= 4} />
              {/* <SideBar.Item label="Customer Point of Contact Details" value={5} /> */}
              <SideBar.Item label="CRL Details" value={5} disabled={activeStep! <= 5} />
            </SideBar>
          </div>
          <FormProvider {...bankDetailForm}>
            {activeStep === 0 && (
              <BankDetailForm onCancelClick={onCancel} onFormSubmit={() => handleApproveClick()} />
            )}
          </FormProvider>
          <FormProvider {...amountDetails}>
            {activeStep === 1 && (
              <AmountDetailForm
                onCancelClick={onCancel}
                onBackClick={() => onBack()}
                onFormSubmit={() => handleApproveClick()}
              />
            )}
          </FormProvider>
          <FormProvider {...advancePaymentForm}>
            {activeStep === 2 && (
              <AdvancePaymentForm
                onCancelClick={onCancel}
                onBackClick={() => onBack()}
                onFormSubmit={() => handleApproveClick()}
              />
            )}
          </FormProvider>
          <FormProvider {...paymentTerm}>
            {activeStep === 3 && (
              <PaymentTermForm
                onCancelClick={onCancel}
                onBackClick={() => onBack()}
                onFormSubmit={() => handleApproveClick()}
              />
            )}
          </FormProvider>
          <FormProvider {...customerDetailForm}>
            {activeStep === 4 && (
              <CustomerDetailForm
                onCancelClick={onCancel}
                onBackClick={() => onBack()}
                onFormSubmit={() => handleApproveClick()}
              />
            )}
          </FormProvider>
          {/* <FormProvider {...pocDetailForm}>{activeStep === 5 && <PocForm onFormSubmit={() => handleApproveClick()} />}</FormProvider> */}
          <FormProvider {...CRLDetailForm}>
            {activeStep === 5 && (
              <CRLDetailsForm
                onFormSubmit={handleDataSubmit}
                onCancelClick={onCancel}
                onBackClick={() => onBack()}
                editMode={false}
              />
            )}
          </FormProvider>
        </section>
        <Loader open={isLoading} />
      </main>
    </OrderContext.Provider>
  );
};

export default GenerateCRL;
