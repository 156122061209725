/* eslint-disable @typescript-eslint/no-var-requires */
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import css from './index.module.scss';
import InternalView from './internal-view';
import CustomerView from './customer-view';
import DocVisiblityView from './doc-visiblity-view';
import Chip from '@components/base/chip';
import { ICustomerPoc } from '@helpers/types/customer';
import AccessWrapper from '@authorization/access-wrapper';
import { USER_TYPES } from '@helpers/constants';
import { AppContext } from '@helpers/hooks/AppContext';
import { useSearchParams } from 'react-router-dom';

interface DocumentMenuItem {
  value: number;
  key: string;
  default?: boolean;
}

interface DocumentTabProps {
  orderId: string;
  readableOrderId: string;
  customerPocs: ICustomerPoc;
  activeSubTab?: number;
  actions: IActions;
  documentMenuMapping: DocumentMenuItem[];
}

const DocumentTab = (props: DocumentTabProps) => {
  const { actions, documentMenuMapping: DOCUMENT_MENU_MAPPING, readableOrderId } = props;
  const { appState } = useContext(AppContext);
  const { userType = null } = appState;
  const [searchParams, setSearchParams] = useSearchParams();
  const getDefaultTab = useCallback(() => {
    const defaultTab = DOCUMENT_MENU_MAPPING.find((tab: DocumentMenuItem) => tab.default);
    return defaultTab ? defaultTab : DOCUMENT_MENU_MAPPING[0];
  }, [DOCUMENT_MENU_MAPPING]);
  const defaultTab = useMemo(() => getDefaultTab(), []);
  const activeSubTabParam = searchParams.get('activeSubTab');
  const initialDocumentState = useMemo(() => {
    return getValueByKey(activeSubTabParam || defaultTab.key);
  }, [activeSubTabParam]);
  
  function getValueByKey(key:string) {
    const state = DOCUMENT_MENU_MAPPING.find(item => item.key === key);
    return state ? state.value : defaultTab.value;
  }
  const [documentTabStates, setDocumentStates] = useState({
    currentTab: initialDocumentState
  });

  const { currentTab } = documentTabStates;

  const changeTab = (tab: number) => {
    setDocumentStates((prevState) => ({ ...prevState, currentTab: tab }));
    const queryKey = DOCUMENT_MENU_MAPPING.find((state) => state.value === tab);
    setSearchParams({
      activeTab: 'documents',
      activeSubTab: queryKey?.key ?? defaultTab.key
    });
  };

  useEffect(() => {
    setDocumentStates((prevState) => ({
      ...prevState,
      currentTab: userType === USER_TYPES.customer ? 1 : initialDocumentState
    }));
  }, [userType]);

  return (
    <>
      <AccessWrapper show={actions?.read} showTo={[USER_TYPES.internal]} byPassShowWithShowTo>
        <div className={css.taskChipWrapper}>
          <Chip
            label="All Documents"
            variant={currentTab == 0 ? `filled` : `outlined`}
            onClick={() => changeTab(0)}
          />
          <Chip
            label="Customer View"
            variant={currentTab == 1 ? `filled` : `outlined`}
            onClick={() => changeTab(1)}
          />
          <Chip
            label="Document Visiblity"
            variant={currentTab == 2 ? `filled` : `outlined`}
            onClick={() => changeTab(2)}
          />
        </div>
        {currentTab == 0 && <InternalView {...props} />}
        {currentTab == 2 && <DocVisiblityView {...props} changeTab={changeTab} />}
      </AccessWrapper>

      {currentTab == 1 && <CustomerView {...props} />}
    </>
  );
};

export default DocumentTab;
