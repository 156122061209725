import httpClient from './index.service';
import { SERVER_CONFIG } from '@helpers/constants';
import { ICreateSupplier, IUpdateSupplierInfo } from '@helpers/types/supplier';

export interface ListParam {
  page?: number;
  supplier_name?: string;
  supplier_name_list?: string;
  product_list?: string;
  supplier_type_list?: string;
  business_type_list?: string;
  created_at_list?: string;
  country?: string;
  kwargs?: string;
}

async function getSupplierList(param?: ListParam, pageNo?: number) {
  try {
    const mergedParams = {
      ...param,
      page: pageNo
    };
    const response = await httpClient.get(`${SERVER_CONFIG.listSuppliers}`, {
      params: {
        ...param,
        page: pageNo
      }
    });
    return response;
  } catch (error: any) {
    return error;
  }
}

async function getSupplierDetail(supplierID: string) {
  try {
    const response = await httpClient.get(`${SERVER_CONFIG.getSupplier}${supplierID}/`);
    return response;
  } catch (error: any) {
    return error;
  }
}

async function createSupplier(param: ICreateSupplier) {
  try {
    const response = await httpClient.post(SERVER_CONFIG.addSupplier, param);
    return response;
  } catch (error: any) {
    return error;
  }
}

async function updateSupplierInfo(
  supplierID: string,
  supplierInfo: IUpdateSupplierInfo,
  is_add_poc?: number
) {
  try {
    const response = await httpClient.patch(
      `${SERVER_CONFIG.updateSupplier}${supplierID}/?${
        is_add_poc ? `is_add_poc=${is_add_poc}` : ``
      }`,
      supplierInfo
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

async function documentUpload(supplier_id: string, body: any) {
  try {
    const response = await httpClient.post(
      `${SERVER_CONFIG.createSupplierDocument}${supplier_id}/`,
      body
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

async function updateDocument(document_id: string, body: any) {
  try {
    const response = await httpClient.patch(`${SERVER_CONFIG.updateDocument}${document_id}/`, body);
    return response;
  } catch (error: any) {
    return error;
  }
}

async function deleteDocument(document_id: string) {
  try {
    const response = await httpClient.delete(
      `${SERVER_CONFIG.updateDocument}${document_id}/delete/`
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

interface ProductList {
  supplier_id?: string;
  supplier_product_grade_id?: string;
  product_grade_id?: string;
  page?: number;
}

async function fetchSupplierProductList(param?: ProductList) {
  try {
    const response = await httpClient.get(`${SERVER_CONFIG.listSupplierProducts}`, {
      params: param
    });
    return response;
  } catch (error: any) {
    return error;
  }
}

async function createProductGrade(body: any) {
  try {
    const response = await httpClient.post(`${SERVER_CONFIG.addSupplier}products/`, body, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response;
  } catch (error: any) {
    return error;
  }
}

async function documentGradeUpload(grade_id: string, body: any) {
  try {
    const response = await httpClient.post(
      `${SERVER_CONFIG.getSupplier}product/documents/${grade_id}/`,
      body
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

async function documentGradeReUpload(grade_id: string, body: any) {
  try {
    const response = await httpClient.patch(
      `${SERVER_CONFIG.getSupplier}product/document/${grade_id}/`,
      body
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

async function documentGradeDelete(grade_id: string) {
  try {
    const response = await httpClient.delete(
      `${SERVER_CONFIG.getSupplier}product/document/${grade_id}/delete`
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

async function getSupplierFilterOptions() {
  try {
    const response = await httpClient.get(`supplier/${SERVER_CONFIG.formFilterOptions.index}/`);
    return response;
  } catch (error: any) {
    return error;
  }
}

async function getSupplierDocumentOptions() {
  try {
    const response = await httpClient.get(
      `supplier/document/${SERVER_CONFIG.formFilterOptions.index}/`
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

async function getSupplierTypeOptions() {
  try {
    const response = await httpClient.get(`${SERVER_CONFIG.formFilterOptions.supplier}`);
    return response;
  } catch (error: any) {
    return error;
  }
}

export {
  getSupplierList,
  getSupplierDetail,
  createSupplier,
  updateSupplierInfo,
  documentUpload,
  updateDocument,
  deleteDocument,
  fetchSupplierProductList,
  createProductGrade,
  documentGradeUpload,
  documentGradeReUpload,
  documentGradeDelete,
  getSupplierFilterOptions,
  getSupplierDocumentOptions,
  getSupplierTypeOptions
};
