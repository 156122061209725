import { memo } from 'react';
import css from './index.module.scss';
import { BoxContainer, InputDatePicker, SelectLabel, TextField } from '@components/common';
import { Controller, useFormContext } from 'react-hook-form';
import { IconNode } from '@components/base';
import Images from '@assets/images';

const PaymentTermContainer = () => {
  const { control } = useFormContext();

  return (
    <BoxContainer title="Payment Term">
      <div className={css.boxWrapper}>
        <div className={css.rowWrapper}>
          <div className={css.fieldsWrapper}>
            <div className={css.dateFieldWrapper}>
              <Controller
                name={`lc_number`}
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    required
                    type="text"
                    inputMode="text"
                    enterKeyHint="done"
                    label="LC Number"
                    placeholder="Enter Letters of Credit Number"
                    error={fieldState.invalid}
                    endIcon={
                      fieldState.invalid && (
                        <IconNode src={Images.alertError} alt="Error Icon" />
                      )
                    }
                    helperText={fieldState.error?.message}
                  />
                )}
              />
              <Controller
                name={`lc_date`}
                control={control}
                render={({ field, fieldState }) => (
                  <InputDatePicker
                    {...field}
                    required
                    label="LC date"
                    onSelect={(day: Date | undefined) => field.onChange(day ?? null)}
                    error={fieldState.invalid}
                    helperText={fieldState?.error?.message}
                    rootClassName={css.fieldSpacing}
                />
                )}
              />
            </div>
          </div>
        </div>
        <div className={css.rowWrapper}>
        <Controller
            name={`customer_lc_issuing_bank`}
            control={control}
            render={({ field, fieldState }) => (
              <SelectLabel
                rootClassName={css.fieldWrapper}
                {...field}
                required
                label="Customer LC Issuing Bank"
                placeholder="Select Customer LC Issuing Bank"
                // options={[{value: 'LC', label: 'LC'}]}
                value={{value: 'LC', label: 'LC'}}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
            />
            )}
          />
          <Controller
            name={`shipping_bill_number`}
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                required
                type="text"
                inputMode="text"
                label="Shipping Bill Number"
                placeholder="Enter Shipping Bill Number"
                error={fieldState.invalid}
                endIcon={
                  fieldState.invalid && (
                    <IconNode src={Images.alertError} alt="Error Icon" />
                  )
                }
                helperText={fieldState.error?.message}
              />
            )}
          />
        </div>
      </div>
    </BoxContainer>
  );
};

export default memo(PaymentTermContainer);
