import { useCallback, useEffect, useState } from 'react';
import css from './index.module.scss';
import { Typography, Modal, IconNode, Button } from '@components/base';
import { SelectLabel, DragDropUpload, TextField, AutoComplete } from '@components/common';
import { useFormContext, Controller, useWatch } from 'react-hook-form';
import Images from '@assets/images';
import { ISelect } from '@helpers/types';
import { getAddDocFilterOptions } from '@services/order.service';
import notify from '@helpers/toastify-helper';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { Chip, TextField as MaterialText } from '@mui/material';

const filter = createFilterOptions<any>();

interface UploadDocumentProps {
  open: boolean;
  onClose: () => void;
  onFormSubmit: (data: any) => void;
  editMode?: string | null;
  availableDocumentType?: ISelect[];
  orderId: string;
}

/**
 * Document Name is commented for now in types and in view, but functionality is kept because it might be brought back
 */
const UploadOrderDocument = (props: UploadDocumentProps) => {
  const { open, onClose, onFormSubmit, editMode, orderId } = props;
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting, errors }
  } = useFormContext();

  const [uploadOrderDocumentState, setUploadOrderDocumentState] = useState({
    documentTypeFilterOptions: [],
    orderItemFilterOptions: [],
    supplierFilterOptions: [],
    tagsFilterOptions: [],
    defaultSupplierOptions: []
  });

  const {
    documentTypeFilterOptions,
    orderItemFilterOptions,
    supplierFilterOptions,
    tagsFilterOptions,
    defaultSupplierOptions
  } = uploadOrderDocumentState;

  const watch = useWatch({
    name: ['document_object', 'type', 'order_item', 'internal_tags'],
    control: control
  });

  const typeWatch = useWatch({
    name: 'type',
    control: control
  });

  const handleDeleteFile = useCallback(() => {
    setValue('document_object', null);
  }, []);

  const handleFileDrop = useCallback((file: any[]) => {
    if (file.length) {
      setValue('document_object', file[0]);
    }
  }, []);

  useEffect(() => {
    getFilterOptions();
  }, []);

  useEffect(() => {
    if (typeWatch?.data_requirements) {
      setValue('internal_tags', typeWatch?.data_requirements?.tags ?? []);
      setValue('product_required', typeWatch?.data_requirements?.order_item ?? false);
      setValue('supplier_required', typeWatch?.data_requirements?.supplier ?? false);
    }
  }, [typeWatch]);

  useEffect(() => {
    if (watch[2]?.supplier_id) {
      setUploadOrderDocumentState((prevState) => ({
        ...prevState,
        supplierFilterOptions: defaultSupplierOptions.filter(
          (item: any) => item.supplier_id === watch[2]?.supplier_id
        )
      }));
    } else {
      setUploadOrderDocumentState((prevState) => ({
        ...prevState,
        supplierFilterOptions: defaultSupplierOptions
      }));
    }
  }, [watch[2]]);

  const getFilterOptions = async () => {
    const response = await getAddDocFilterOptions(orderId);
    if (response?.success) {
      const { data } = response;
      setUploadOrderDocumentState({
        documentTypeFilterOptions: data.document_type_filter_options,
        orderItemFilterOptions: data.order_item_filter_options,
        supplierFilterOptions: data.supplier_filter_options,
        tagsFilterOptions: data.internal_tags_filter_options,
        defaultSupplierOptions: data.supplier_filter_options
      });
    } else if (response.error) {
      notify({ message: response.error, severity: 'error' });
    } else {
      notify({ message: 'Unable to fetch filter options', severity: 'error' });
    }
  };

  const filterSupplierOptions = (e: any) => {
    const filteredSupplierOptions = supplierFilterOptions.filter(
      (item: any) => item.supplier_id === e.supplier_id
    );
    setValue('supplier', null);
    setValue('order_item', e);
    setUploadOrderDocumentState((prevState) => ({
      ...prevState,
      filteredSupplierOptions: filteredSupplierOptions
    }));
  };

  const onInputSelection = (e: any, p: any) => {
    setValue('internal_tags', p);
  };
  return (
    <Modal open={open} onClose={onClose}>
      <div className={css.modalWrapper}>
        <section className={css.modalHeader}>
          <Typography variant="h4">{editMode ? `Edit` : `Upload`} Document</Typography>
          <IconNode
            src={Images.crossBlack}
            alt="close icon"
            component="button"
            className={css.closeButton}
            onClick={onClose}
          />
        </section>
        <form noValidate className={css.formWrapper} onSubmit={handleSubmit(onFormSubmit)}>
          <section className={css.modalContent}>
            {editMode != 'SYSTEM_GENERATED' && (
              <Controller
                name="type"
                control={control}
                render={({ field, fieldState }) => (
                  <SelectLabel
                    {...field}
                    required
                    label="Document Type"
                    placeholder="Select one"
                    isSearchable
                    isDisabled={editMode ? true : false}
                    options={documentTypeFilterOptions}
                    rootClassName={css.typeField}
                    getOptionLabel={(option: any) => `${option.label}`}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            )}
            {editMode != 'SYSTEM_GENERATED' && (
              <Controller
                name="name"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    inputMode="text"
                    rootClassName={css.typeField}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    placeholder="Start typing"
                    label="Document Name"
                  />
                )}
              />
            )}

            <Controller
              name="internal_tags"
              control={control}
              render={({ field, fieldState }) => (
                <AutoComplete
                  {...field}
                  multiple
                  id="tags-outlined"
                  disableClearable
                  options={tagsFilterOptions}
                  filterOptions={(options: any, params: any) => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;
                    const isExisting = options.some((option: any) => inputValue === option.title);
                    if (inputValue !== '' && !isExisting) {
                      filtered.push({
                        value: inputValue,
                        label: inputValue
                      });
                    }
                    return filtered;
                  }}
                  keyOption="label"
                  onInputSelection={onInputSelection}
                  label="Add Tags"
                  width="auto"
                  placeholder="Select multiple"
                  required={true}
                  rootClassName={css.typeField}
                  freeSolo
                  renderTags={(tagValue: any, getTagProps: any) =>
                    tagValue.map((option: any, index: any) => (
                      <Chip label={option.label} key={index} disabled={option?.is_system_defined} />
                    ))
                  }
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
            {editMode != 'SYSTEM_GENERATED' && (
              <Controller
                name="order_item"
                control={control}
                render={({ field, fieldState }) => (
                  <SelectLabel
                    {...field}
                    label="Select Product"
                    placeholder="Select one"
                    isSearchable
                    required={typeWatch ? typeWatch.data_requirements?.order_item : false}
                    options={orderItemFilterOptions}
                    rootClassName={css.typeField}
                    getOptionLabel={(option: any) => `${option.order_item_name}`}
                    getOptionValue={(option: any) => option.order_item_id}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    onChange={(e: any) => filterSupplierOptions(e)}
                  />
                )}
              />
            )}
            {editMode != 'SYSTEM_GENERATED' && (
              <Controller
                name="supplier"
                control={control}
                render={({ field, fieldState }) => (
                  <SelectLabel
                    {...field}
                    label="Select Supplier"
                    placeholder="Select one"
                    isSearchable
                    required={typeWatch ? typeWatch.data_requirements?.supplier : false}
                    options={supplierFilterOptions}
                    rootClassName={css.typeField}
                    getOptionLabel={(option: any) => `${option.supplier_name}`}
                    getOptionValue={(option: any) => option.supplier_id}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            )}

            <Controller
              name="remarks"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  inputMode="text"
                  rootClassName={css.typeField}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  placeholder="Start typing"
                  label="Remarks"
                />
              )}
            />
            <Controller
              name="document_object"
              control={control}
              render={({ fieldState }) => (
                <DragDropUpload
                  onDrop={handleFileDrop}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />

            {watch[0] && (
              <div className={css.fileUploadState}>
                <Typography variant="p" className={css.fileName}>
                  {watch[0].name}
                </Typography>
                <IconNode
                  alt="delete icon"
                  component="button"
                  src={Images.deleteDark}
                  className={css.deleteButton}
                  onClick={handleDeleteFile}
                />
              </div>
            )}
            {editMode && watch[0] && (
              <Controller
                name="reason_for_update"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    required={true}
                    label="Reason for Re-Upload"
                    placeholder="Please provide a reason for re-uploading the document"
                    error={fieldState.invalid}
                    rootClassName={css.typeField}
                    endIcon={
                      fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                    }
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            )}
          </section>
          <section className={css.modalFooter}>
            <Button variant="outlined-secondary" onClick={onClose} disabled={isSubmitting}>
              Cancel
            </Button>
            <Button type="submit" disabled={isSubmitting}>
              {editMode ? 'Update' : 'Save'}
            </Button>
          </section>
        </form>
      </div>
    </Modal>
  );
};

export default UploadOrderDocument;
