import { Fragment, useCallback } from 'react';
import css from './index.module.scss';
import { Typography, Button, IconNode, Divider, CheckboxLabel } from '@components/base';
import { BoxContainer, TextField, AddressFields, InputDatePicker } from '@components/common';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import Images from '@assets/images';

const CRLDetailsFields = () => {
  const { control } = useFormContext();

  return (
    <BoxContainer title="CRL Details">
      <div className={css.accordionBodyWrapper}>
        <div className={css.contentWrapper}>
          <div className={css.fieldsWrapper}>
            <div className={css.fieldWrapper}>
              <Controller
                name={`document_date`}
                control={control}
                render={({ field, fieldState }) => (
                  <InputDatePicker
                    {...field}
                    required
                    label="CRL Date"
                    onSelect={(day: Date | undefined) => field.onChange(day ?? null)}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    rootClassName={css.fieldSpacing}
                  />
                )}
              />
              <Controller
                name={`crl_remitted_amount`}
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    required
                    type="text"
                    inputMode="decimal"
                    enterKeyHint="next"
                    label="CRL Remitted Amount"
                    placeholder="Enter CRL Remitted amount"
                    error={fieldState.invalid}
                    endIcon={
                      fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                    }
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </div>
          </div>
          <div className={css.fieldsWrapper}>
            <div className={css.fieldWrapper}>
              <Controller
                name={`crl_remitted_amount_in_words`}
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    required
                    type="text"
                    inputMode="text"
                    enterKeyHint="next"
                    label="CRL Remitted Amount in words"
                    placeholder="Enter CRL Remitted Amount in words"
                    error={fieldState.invalid}
                    endIcon={
                      fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                    }
                    helperText={fieldState.error?.message}
                  />
                )}
              />
              <Controller
                name={`value_date`}
                control={control}
                render={({ field, fieldState }) => (
                  <InputDatePicker
                    {...field}
                    required
                    label="Value Date"
                    onSelect={(day: Date | undefined) => field.onChange(day ?? null)}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    rootClassName={css.fieldSpacing}
                  />
                )}
              />
            </div>
          </div>
          <div className={css.fieldsWrapper}>
            <div className={css.fieldWrapper}>
              <Controller
                name={`customers_correspondent_bank_details`}
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    type="text"
                    inputMode="text"
                    enterKeyHint="next"
                    label="Customers Correspondent Bank Details"
                    placeholder="Enter Customers Correspondent Bank Details"
                    error={fieldState.invalid}
                    endIcon={
                      fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                    }
                    helperText={fieldState.error?.message}
                  />
                )}
              />
              <Controller
                name={`purpose_of_remittance`}
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    type="text"
                    inputMode="text"
                    enterKeyHint="next"
                    label="Purpose Of Remittance"
                    placeholder="Enter Purpose Of Remittance"
                    error={fieldState.invalid}
                    endIcon={
                      fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                    }
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </div>
          </div>
          <div className={css.fieldsWrapper}>
            <div className={css.fieldWrapper}>
              <Controller
                name={`foreign_bank_charges`}
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    type="text"
                    required
                    inputMode="text"
                    enterKeyHint="next"
                    label="Foreign Bank Charges"
                    placeholder="Enter Foreign Bank Charges"
                    error={fieldState.invalid}
                    endIcon={
                      fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                    }
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </div>
          </div>
          <div className={css.fieldsWrapper}>
            <div className={css.fieldWrapper}>
              <Controller
                name={`debit_from_cc_acc_no`}
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    type="text"
                    required
                    inputMode="text"
                    enterKeyHint="next"
                    label="Cash credit account no"
                    placeholder="Enter Cash Credit Account No"
                    error={fieldState.invalid}
                    endIcon={
                      fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                    }
                    helperText={fieldState.error?.message}
                  />
                )}
              />
              <Controller
                name={`amount_to_debit_from_cc`}
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    type="text"
                    required
                    inputMode="text"
                    enterKeyHint="next"
                    label="Amount to be debited from Cash credit account"
                    placeholder="Enter Amount to be debit from Cash credit account"
                    error={fieldState.invalid}
                    endIcon={
                      fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                    }
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </div>
          </div>
          <div className={css.fieldsWrapper}>
            <div className={css.fieldWrapper}>
              <Controller
                name={`port_of_loading`}
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    type="text"
                    required
                    inputMode="text"
                    enterKeyHint="next"
                    label="Port of loading"
                    placeholder="Enter Port of loading"
                    error={fieldState.invalid}
                    endIcon={
                      fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                    }
                    helperText={fieldState.error?.message}
                  />
                )}
              />
              <Controller
                name={`forward_contract_no_and_date`}
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    type="text"
                    inputMode="text"
                    enterKeyHint="next"
                    label="Forward contract no. & date"
                    placeholder="Enter Forward Contract no. & Date"
                    error={fieldState.invalid}
                    endIcon={
                      fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                    }
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </div>
          </div>
          <div className={css.fieldsWrapper}>
            <div className={css.fieldWrapper}>
              <Controller
                name={`forward_contract_amount`}
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    type="text"
                    inputMode="text"
                    enterKeyHint="next"
                    label="Forward contract amount"
                    placeholder="Enter Forward Contract Amount"
                    error={fieldState.invalid}
                    endIcon={
                      fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                    }
                    helperText={fieldState.error?.message}
                  />
                )}
              />
              <Controller
                name={`amount_to_be_utilized`}
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    type="text"
                    inputMode="text"
                    enterKeyHint="next"
                    label="Amount to be utilized for this remittance"
                    placeholder="Enter Amount to be utilized for this remittance"
                    error={fieldState.invalid}
                    endIcon={
                      fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                    }
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </div>
          </div>
          <div className={css.fieldsWrapper}>
            <div className={css.fieldWrapper}>
              <Controller
                name={`due_date_of_contract`}
                control={control}
                render={({ field, fieldState }) => (
                  <InputDatePicker
                    {...field}
                    label="Due date of the contract"
                    onSelect={(day: Date | undefined) => field.onChange(day ?? null)}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    rootClassName={css.fieldSpacing}
                  />
                )}
              />
            </div>
          </div>
          <div className={css.fieldsWrapper}>
            <div className={css.fieldWrapper}>
              <Controller
                name={`advance_import_remittance`}
                control={control}
                render={({ field, fieldState }) => (
                  <CheckboxLabel
                    {...field}
                    label="Is the advance import remittance being made for import of capital goods/equipment?"
                    value={`${field.value}`}
                    checked={field.value}
                  />
                )}
              />
            </div>
          </div>
          <div className={css.fieldsWrapper}>
            <div className={css.fieldWrapper}>
              <Controller
                name={`merchanting_trade_transaction`}
                control={control}
                render={({ field, fieldState }) => (
                  <CheckboxLabel
                    {...field}
                    label="Is the remittance being made for merchanting trade transaction?"
                    value={`${field.value}`}
                    checked={field.value}
                  />
                )}
              />
            </div>
          </div>
          <div className={css.fieldsWrapper}>
            <div className={css.fieldWrapper}>
              <Controller
                name={`part_payment`}
                control={control}
                render={({ field, fieldState }) => (
                  <CheckboxLabel
                    {...field}
                    label="Part Payment of the total shipment"
                    value={`${field.value}`}
                    checked={field.value}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </BoxContainer>
  );
};

export default CRLDetailsFields;
