import css from './index.module.scss';
import { Button, IconNode } from '@components/base';
import { AddressFields, BoxContainer, TextField } from '@components/common';
import { Controller, useFormContext } from 'react-hook-form';
import Images from '@assets/images';

interface CustomerDetailFormProps {
  onCancelClick?: () => void;
  onFormSubmit: (data: any) => void;
  onBackClick?: () => void;
}

const CustomerDetailForm = (props: CustomerDetailFormProps) => {
  const { onCancelClick, onFormSubmit, onBackClick } = props;
  const { handleSubmit, control } = useFormContext();

  return (
    <form noValidate onSubmit={handleSubmit(onFormSubmit)} className={css.formWrapper}>
      <BoxContainer title="Customer Details">
        <div className={css.boxWrapper}>
          <Controller
            name="name"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                required
                autoFocus
                type="text"
                disabled
                inputMode="text"
                enterKeyHint="next"
                autoComplete="name"
                label="Customer Name"
                placeholder="Enter Customer Name"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                }
              />
            )}
          />
          <div className={css.addressWrapper}>
            <AddressFields
              firstAddressLine="address_line1"
              secondAddressLine="address_line2"
              zipCode="zip_code"
              disabled
              city="city"
              state="state"
              country="country"
              suffix="address_suffix"
            />
          </div>
        </div>
      </BoxContainer>
      <div className={css.actionButtonWrapper}>
        <Button variant="text" onClick={onCancelClick}>
          Cancel
        </Button>
        <div className={css.actionWrapper}>
          <Button variant="outlined-secondary" onClick={onBackClick}>
            Back
          </Button>
          <Button onClick={onFormSubmit}>Save & Proceed</Button>
        </div>
      </div>
    </form>
  );
};

export default CustomerDetailForm;
